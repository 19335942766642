import metaTags from '@/config/meta';

const log = (message) => {
  if (!import.meta.env.DEV) {
    return;
  }

  console.log(message);
};

const useMeta = (params = {}) => {
  const route = useRoute();

  const tags = metaTags(toValue(params))[route.name];

  log('---------------------------------');
  log(`SEO ROUTE NAME: ${route.name}`);
  log(`SEO AVAILABLE PARAMS:`);
  log(toValue(params));

  if (!tags) {
    log(`SEO TAGS NOT FOUND`);
    return;
  }

  if (tags.title) {
    log(`SEO TITLE TAG: ${tags.title}`);
  }

  if (tags.meta) {
    log(`SEO DESCRIPTION TAG: ${tags.meta[0].content}`);
  }

  log('---------------------------------');

  //tags.robots = '<meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />'

  useHead(tags);
};

export { useMeta };
