export default (params: Record<string, any>) => ({
  index: {
    title: `haystack.ie | Ireland's farming marketplace`,
    meta: [{ name: 'description', content: 'Buy it, sell it, farm it.' }],
  },
  category: {
    title: `${params?.category?.name} | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `The very best ${params?.category?.name} in Ireland.`,
      },
    ],
  },
  search: {
    title: `${params?.terms} | haystack.ie - Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `The very best ${params?.terms} in Ireland.`,
      },
    ],
  },
  'category-for-sale-slug': {
    title: `${params?.post?.title} for sale | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `The very best ${params?.post?.title} in Ireland.`,
      },
    ],
  },
  'terms-and-conditions': {
    title: `Terms and Conditions | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `Terms and conditions for use of the Haystack service.`,
      },
    ],
  },
  'animal-policy': {
    title: `Animal policy | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `Animal policy for the Haystack service.`,
      },
    ],
  },
  'cookie-policy': {
    title: `Cookie policy | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `Cookie policy for the Haystack service.`,
      },
    ],
  },
  'dealer-enquiries': {
    title: `Dealer enquiries | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `How to open a dealer account on Haystack.`,
      },
    ],
  },
  'privacy-policy': {
    title: `Privacy policy | haystack.ie | Ireland's farming marketplace`,
    meta: [
      {
        name: 'description',
        content: `Privacy policy for the Haystack service.`,
      },
    ],
  }
});
/*
<meta name="msapplication-TileColor" content="#000000">
<meta name="apple-itunes-app" content="app-id=912366362" />
<meta name="google-play-app" content="app-id=com.agriland.agriland">
<meta name="theme-color" content="#ffffff">
<meta name="apple-mobile-web-app-status-bar-style" content="#3a9948">
<meta name="fb:pages" content="426875164071704">
<meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
<meta property="og:locale" content="en_GB" />
<meta property="og:type" content="website" />
<meta property="og:title" content="The latest farming news for Irish farmers" />
<meta property="og:description" content="Ireland&#039;s largest farming news portal. Daily Irish farming news and Irish agricultural news for farmers in Ireland. Farm Ireland advice and farming updates" />
<meta property="og:url" content="https://www.agriland.ie/" />
<meta property="og:site_name" content="Agriland.ie" />
<meta property="article:publisher" content="https://www.facebook.com/AgrilandIreland" />
<meta property="article:modified_time" content="2023-09-07T16:25:35+00:00" />
<meta property="og:image" content="https://cdn.agriland.ie/uploads/2021/03/agriland_fallback-1.jpg" />
<meta property="og:image:width" content="1920" />
<meta property="og:image:height" content="1080" />
<meta property="og:image:type" content="image/jpeg" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="The latest farming news for Irish farmers" />
<meta name="twitter:description" content="Ireland&#039;s largest farming news portal. Daily Irish farming news and Irish agricultural news for farmers in Ireland. Farm Ireland advice and farming updates" />
<meta name="twitter:image" content="https://cdn.agriland.ie/uploads/2021/03/agriland_fallback-1.jpg" />
<meta name="twitter:site" content="@agrilandireland" />
*/